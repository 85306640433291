.inputWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;

    label {
        margin-bottom: 0;
    }
}

.form {
    >div {
        display: flex;
        width: 100%;
        gap: 25px;
        margin-bottom: 25px;
    }
}

.actions {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
}

.customInputError {
    display: flex;
    align-items: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    gap: 0.15rem;
    font-weight: 600;
    color: rgb(239 68 68);
    background-color: rgb(254 226 226);
    border-radius: 0.375rem;

    svg {
        margin-bottom: 4px;
    }
}

@media screen and (max-width: 768px) {
    .form>div {
        flex-wrap: wrap;
        gap: 20px;
    }
}