
  .autoCompleteContainer {
      position: relative;
    }
  .autoCompleteContainer .autoCompleteOverlay {
    border-radius: 2px;
    z-index: 10;
    position: absolute;
    width: 100%;
    top: 100%;
    /* background: coral; */
    background-color: #FCFCFC;
    border: 1px solid hsl(0, 0%, 93%);
  }
  .autoCompleteOverlay ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
  .autoCompleteOverlay li {
    padding: 12px 15px 12px 15px;
    cursor: pointer;
    border-bottom: 1px solid #EDEDED;
    will-change: background-color;
    transition: background-color .2s ease-in-out;
  }
  .autoCompleteOverlay li.selected {
    background-color: #F3F3F3;
  }
  .autoCompleteOverlay li:last-child {
    border: none;
  }
  .autoCompleteOverlay span.completion {
    color: #0787ad;
  }