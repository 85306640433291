.checkboxGrid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}
.infoContainer {
  text-align: right; 
  font-weight: bold;
}

 @media screen and (max-width: 980px) {
  .checkboxGrid {
 grid-template-columns: repeat(1, 1fr);
  }
 }