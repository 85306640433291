.basicBox {
  padding: 0.9rem;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
}

.row {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}

.marginBox {
  @extend .basicBox;
  margin: 15px 0px 8px 0px;
}

.margin10 {
  margin: 10px;
}

.container2 {
  padding: 0.9rem;
  background-color: #2c2c2c;
  display: flex;
  align-items: center;
}

.noDecoration {
  color: inherit;
  text-decoration: none;
  align-items: center;
  display: flex;
}

.center {
  display: flex;
  justify-content: center;
}

.rowSpace {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.centerMargin {
  @extend .rowSpace;
  margin: 8px auto;
}

.centerPointer {
  @extend .center;
  cursor: pointer;
}

.marginPointer {
  @extend .center;
  cursor: pointer;
  margin: 0 5px;
  background-color: transparent;
  border: none;
}

.smallFont {
  font-size: 12px;
  font-weight: 600;
}
.bigIconBox {
  @extend .center;
  width: 60px;
}

.iconBox {
  @extend .center;
  width: 30px;
}
.buttonBox {
  @extend .center;
  width: 100px;
}
.icon {
  @extend .center;
  margin-left: 2px;
  font-size: 35px;
}

.warningIcon {
  @extend .icon;
  cursor: pointer;
  color: red;
}
.iconsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}
.okIcon {
  @extend .icon;
  color: green;
}

.pendingIcon {
  @extend .icon;
  cursor: pointer;
  color: blue;
}

.refreshIcon {
  @extend .icon;
  cursor: pointer;
}

.wrapRowSpace {
  @extend .rowSpace;
  padding: 10px;
  flex-wrap: wrap;
}
.width20rowSpace {
  @extend .rowSpace;
  width: 20%;
}

.width70rowSpace {
  @extend .rowSpace;
  width: 70%;
}

.marginPointerBox {
  @extend .marginPointer;
  border: 1px solid blue;
  border-radius: 5px;
  padding: 1px;
}

.semaforo {
  width: 20px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

@media (max-width: 600px) {
  .semaforo {
    width: 60px;
    height: 20px;
    display: flex;
    flex-direction: row;
    align-content: space-around;
  }
}

.filtersContent {
  padding: 9px;
  box-shadow: 0px 0px 20px -7px rgba(0, 0, 0, 0.75);
}
.basicBox {
  padding: 0.9rem;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
}

.row {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}

.marginBox {
  @extend .basicBox;
  margin: 15px 0px 8px 0px;
}

.margin10 {
  margin: 10px;
}

.noDecoration {
  color: inherit;
  text-decoration: none;
  align-items: center;
  display: flex;
}

.center {
  display: flex;
  justify-content: center;
}

.rowSpace {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.centerMargin {
  @extend .rowSpace;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin: 8px auto;
  flex-wrap: wrap;
}

.centerPointer {
  @extend .center;
  cursor: pointer;
}

.marginPointer {
  @extend .center;
  cursor: pointer;
  margin: 0 5px;
}

.smallFont {
  font-size: 12px;
  font-weight: 600;
}

.bigIconBox {
  @extend .center;
  width: 60px;
}

.iconBox {
  @extend .center;
  width: 30px;
}

.buttonBox {
  @extend .center;
  width: 100px;
}

.icon {
  @extend .center;
  margin-left: 2px;
  font-size: 35px;
}

.warningIcon {
  @extend .icon;
  cursor: pointer;
  color: red;
}

.iconsContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.okIcon {
  @extend .icon;
  color: green;
}

.pendingIcon {
  @extend .icon;
  cursor: pointer;
  color: blue;
}

.refreshIcon {
  @extend .icon;
  cursor: pointer;
}

.wrapRowSpace {
  @extend .rowSpace;
  padding: 10px;
  flex-wrap: wrap;
}

.width20rowSpace {
  @extend .rowSpace;
  width: 20%;
}

.width70rowSpace {
  @extend .rowSpace;
  width: 70%;
}

.marginPointerBox {
  @extend .marginPointer;
  border: 1px solid blue;
  border-radius: 5px;
  padding: 1px;
}

.semaforo {
  width: 20px;
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

@media (max-width: 600px) {
  .semaforo {
    width: 60px;
    height: 20px;
    display: flex;
    flex-direction: row;
    align-content: space-around;
  }
}

.filtersContent {
  padding: 9px;
  box-shadow: 0px 0px 20px -7px rgba(0, 0, 0, 0.75);
}

.btnFlexicarMini {
  margin: auto 2px;
  padding: 1px 3px;
  color: #fff !important;
  border-radius: 5px;
  font-weight: bold;
  font-size: 10px;
  width: 100% !important;

}

.green {
  background-color: green !important;
  border-color: green !important;

}

.red {
  background-color: red !important;
  border-color: red !important;
}

.grey {
  background-color: grey !important;
  border-color: grey !important;
}

.yellow {
  background-color: #ffc107 !important;
  border-color: #ffc107 !important;
}

.disabledBtn {
  cursor: not-allowed !important;
}