.classFilterList {
  background: #ff5d0e;
  color: #fff;
  border-bottom: 1px solid #fff;
  margin-left: 4px;
  padding: 3px;
  border-radius: 4p;
}

.avgBox {
  text-align: center;
  background-color: #f1f1f1;
  border-radius: 10px;
  align-self: flex-start;
  margin: 0 10px 20px 10px;
  padding: 10px 0;
}

.webkitBox {
  display: -webkit-box !important;
}

.w90 {
  width: 90% !important;
}
.container2 {
  padding: 0.9rem;
  background-color: #2c2c2c;
  display: flex;
  align-items: center;
}