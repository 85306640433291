.card {
    padding: 2rem;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
}

.person_info {
    display: flex;
    flex-flow: column;

    label {
        color: #21A8F8;
        margin: 0;
    }

    span {
        margin-bottom: 1rem;
    }
}

.chip {
    display: inline-block;
    height: 32px;
    font-size: 13px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.8);
    line-height: 32px;
    padding: 0 12px;
    border-radius: 16px;
    background-color: #e4e4e4;
    margin-bottom: 5px;
    margin-right: 5px;
}

.table {
    td,
    th {
        width: 12.5%;
        text-align: center;
        vertical-align: middle !important;
    }
}