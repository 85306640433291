.title {
  text-align: center;
}

.css-53l18y-ReactDropdownSelect {
  margin: 0.25em 0;

}

.flex {
  padding-bottom: 10px;
  display: flex;
}

.wrapper.text-center.sub-menu {
  margin-bottom: 20px;
}

.disabled {

  color: black i !important;
  background-color: #8f8c8c94 !important;
  opacity: 0.7 !important;


}

.row {
  width: auto !important;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
  display: flex;
}

.disabled>.react-dropdown-select-dropdown-handle {
  display: none !important;
}

.outline {
  border: none !important;
  border-bottom: 1px solid #ccc !important;
}

.switch {
  line-height: 18px;
  -webkit-box-align: center;
  align-items: center;
  margin-bottom: 8px;
  display: inline-block;
  position: relative;
  white-space: nowrap;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 3em;
  padding: 1px;
  margin-left: 10px;
}

.switch input {
  appearance: none;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  margin: 0px;
  padding: 0px;
  border: none;
  background: transparent;
  cursor: pointer;
  border-radius: 3em;
}


.switch span {
  text-align: center;
  font-size: 12px;
  font-weight: 700;
  line-height: 1;
  cursor: pointer;
  display: inline-block;
  padding: 7px 15px;
  transition: all 100ms ease-out 0s;
  user-select: none;
  border-radius: 3em;
  color: rgba(51, 51, 51, 0.6);
  background: transparent;
}

.switch span:last-of-type {
  padding-left: 8px;
}

.switch input:checked~span:first-of-type,
.switch input:not(:checked)~span:last-of-type {
  background: rgb(255, 255, 255);
  //box-shadow: rgb(0 0 0 / 20%) 0px 0px 2px;
  color: rgb(51, 51, 51);
  padding: 7px 15px;
}

.bold {
  font-weight: bolder;
  margin: 20px 0;
}

.jss605 {
  color: #6b6b6b;
  margin: 1rem -1rem 0.5rem;
  display: flex;
  padding: 0.5rem;
  align-items: center;
  justify-content: center;
  background-color: #efefef;
}

.floatingLabel {
  position: relative;
  margin-bottom: 10px;

  .react-dropdown-select {
    border-bottom: 1px solid #ccc;
  }

  label {
    font-size: 0.7rem;
    position: absolute;
    top: calc(50% - 10px);
    left: 12px;
    opacity: 0;
    transition: all .3s ease;
    color: #9e9e9e
  }

  input:not(:placeholder-shown) {
    padding: 28px 10px 12px 10px;
  }

  input:not(:placeholder-shown)+label {
    transform: translateY(-10px);
    opacity: 1;
  }

  .filter input:not(:placeholder-shown) {
    padding: 5px 5px 0 0;
  }

  .filter input:not(:placeholder-shown)+label {
    transform: translateY(-20px);
    opacity: 1;
  }

  .filter span {
    padding-top: 12px;
  }

  .select {
    font-size: 0.7rem;
    position: absolute;
    top: calc(12% - 7px);
    left: 20px;
    opacity: 1;
    transition: all .3s ease;
    color: #9e9e9e;
  }

  .notSelect {
    opacity: 0;
  }
}

@media only screen and (max-width: 600px) {
  .select {
    width: 100% !important;
  }

  .floatingLabel {
    padding-right: 0 !important;
    width: 100% !important;
    padding-left: 0 !important;
    margin-left: 0 !important;
  }


}