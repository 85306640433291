
.dropdown-select{
  overflow: "hidden";
  text-overflow: ellipsis;
  min-width: fit-content;
   }
  

@media (max-width: 500px)
 {
.dropdown-select{
    font-size: 16px !important;
   }
 }