.btnSecondary {
    padding: 3px 10px;
    color: #ff5d0e;
    background-color:#FFF;
    border-color: #ff5d0e;
    border-radius: 4px;
    font-weight: bold;
}
.btnFlexicar {
    padding: 3px 10px;
    color: #FFF;
    background-color:#ff5d0e;
    border-color: #ff5d0e;
    border-radius: 4px;
    font-weight: bold;
}
.btnSecondary:disabled {
    color: #BCBCBC;  
    border-color: #BCBCBC;
    cursor: not-allowed;  
}
.btnFlexicar:disabled {
    color: #FFF;  
    border-color: #BCBCBC;
     background-color:#BCBCBC;
    cursor: not-allowed;  
}