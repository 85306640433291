.blockEdit {
  opacity: .3;
  pointer-events: none;
}

.input {
  width: 110px;
}

.subTitle {
  margin-left: 1.5rem;
  color: #181818;
}