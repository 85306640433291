.form {
    label {
        margin-bottom: 0;
        margin-top: 1rem;
    }

    >div {
        display: flex;
        flex-direction: column;
        margin-bottom: 2rem;

        >span {
            font-weight: bold;
            font-size: 18px;
            line-height: 24px;
        }
    }

    .dataClientWrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        >div {
            flex: 0 0 calc(33% - .5rem);
        }

        @media screen and (max-width: 768px) {
            >div {
                flex: 0 0 100% !important;
            }
        }
    }

    .dataVehicleWrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        >div {
            flex: 0 0 calc(33% - .5rem);

            &:last-child,
            &:nth-last-child(2) {
                flex: 0 0 calc(50% - .5rem);
            }
        }

        @media screen and (max-width: 768px) {
            >div {
                flex: 0 0 100% !important;
            }
        }
    }

    .dataDealershipWrapper {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;

        >div {
            flex: 0 0 calc(33% - .5rem);

            &:nth-child(4),
            &:nth-child(5) {
                flex: 0 0 calc(50% - .5rem);

                >div {
                    display: flex;
                    flex-direction: row;
                    gap: 20px;

                    >div {
                        display: flex;
                        gap: 20px;
                    }
                }
            }

            &:last-child {
                flex: 0 0 100%;
            }
        }

        @media screen and (max-width: 768px) {
            >div {
                flex: 0 0 100% !important;

                &:nth-child(4),
                &:nth-child(5) {
                    >div {
                        flex-direction: column;
                        gap: 0;

                        >div>label {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }
}


.customSelectError {
    display: flex;
    align-items: center;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    gap: 0.15rem;
    font-weight: 600;
    color: rgb(239 68 68);
    background-color: rgb(254 226 226);
    border-radius: 0.375rem;

    svg {
        margin-bottom: 5px;
    }
}


$--var-color-active: #D34600;
$--var-color-disabled: #959495;

.customRadioButtonWrapper {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    span {
        cursor: pointer;
    }

    .disabled {
        color: $--var-color-disabled;
        cursor: not-allowed;
    }

    input[type="radio"] {
        -webkit-appearance: none;
        appearance: none;
        margin: 0;
        font: inherit;
        width: 1.15em;
        height: 1.15em;
        border: 0.15em solid #6B6B6B;
        border-radius: 50%;
        transform: translateY(-0.075em);

        display: grid;
        place-content: center;
        cursor: pointer;

        &::before {
            content: "";
            width: 0.65em;
            height: 0.65em;
            border-radius: 50%;
            transform: scale(0);
            transition: 120ms transform ease-in-out;
            box-shadow: inset 1em 1em $--var-color-active
        }

        &:checked {
            border: 1px solid $--var-color-active;

            &::before {
                transform: scale(1);
            }
        }

        &:disabled {
            color: $--var-color-disabled;
            cursor: not-allowed;
            background-color: #fff;
            border: 1px solid $--var-color-disabled;

            &::before {
                box-shadow: inset 1em 1em $--var-color-disabled;
            }
        }
    }
}