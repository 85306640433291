
.title {
  text-align: center;
}

.css-53l18y-ReactDropdownSelect {
    margin: 0.25em 0;

}
.flex {
    padding-bottom: 10px;
    display: flex;
}
.wrapper.text-center.sub-menu {
    margin-bottom: 20px;
}
.filter {
    margin-bottom: 10px;
}
.arrow{
        color: #f34c36;
    margin-left: 5px;
}
.create{
    position: fixed;
    bottom: 10px;
    right: 10px;
    border-radius: 100%  !important;
}
.centerButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
}
.accordionButton{

    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    
    font-size: 1rem;
    color: #212529;
    text-align: left;
    background-color: #fff;
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out, border-radius .15s ease;
}
.accordionButton:focus {
    z-index: 3;
    border-color: #f6c5be;;
    outline: 0;
    box-shadow: 0 0 0 0.25rem #f34c3664;;
}
.accordionButton:not(.collapsed) {
    color: #000000 !important;
    background-color: transparent !important;
    box-shadow: none !important;
}
