.changePasswordForm {
    padding: 2rem;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);

    label {
        margin-bottom: 0;
        font-weight: bold;
    }
}


.buttonPassword {
    width: auto;

    @media screen and (max-width: 768px) {
        width: 100%;
    }
}