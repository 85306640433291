
#main {
   
    margin: 0 auto;
    background-color: aliceblue;
    padding: 10px;
    border: 1px solid #007bff;
    border-radius: 10px;
    cursor: pointer;
    box-shadow: 0 0.25rem 0.75rem rgb(0 0 0 / 10%);
    
}
span.no-display {
    display: none;
}
#main input {
    position: absolute;
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    outline: none;
    opacity: 0;
    top: 0;
    left: 0;
    cursor: pointer;
}
#main button {
  margin: 0;
  color: #fff;
  background: #16a085;
  border: none;
  width: 508px;
  height: 35px;
  margin-top: -20px;
  margin-left: -4px;
  border-radius: 4px;
  border-bottom: 4px solid #117a60;
  transition: all 0.2s ease;
  outline: none;
}
#main button:hover {
  background: #149174;
  color: #0c5645;
}
#main button:active {
  border: 0;
}

.image-order {
  align-items: center;
  justify-content: center;
}
.delete-icon {
  cursor: pointer;
}

.wrapper {
  margin: 5% auto;
  
  align-items: center;
  justify-content: center;
}

div.gallery {
    margin: 5px;
    border-radius: 10px;
    float: left;
    box-shadow: 0 0.25rem 0.75rem rgb(0 0 0 / 10%);
     transition: transform .4s;
     position: relative;
}

div.gallery:hover {
   transform: scale(1.1);

}

div.gallery img {
    width: 100%;
    height: 140px;
    border-radius: 10px 10px 0px 0px;
}

div.desc {
    text-align: center;
    position: absolute;
    top: 0;
    right: 0;
    background: white;
    border-radius: 100%;
    width: 27px;
    height: 27px;
    color: red;
}

.error-msg {
  color: salmon;
}
label.pointer {

    display: flex;
    justify-content: center;
    align-items: center;
}

span.principal {
    position: absolute;
    background: #ff5722;
    color: white;
    padding: 0 5px;
    border-radius: 10px;
}
