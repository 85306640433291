
.title {
  text-align: center;
}

.css-53l18y-ReactDropdownSelect {
    margin: 0.25em 0;

}
.flex {
    padding-bottom: 10px;
    display: flex;
}
.wrapper.text-center.sub-menu {
    margin-bottom: 20px;
}
.filter {
    margin-bottom: 10px;
}
.arrow{
        color: #f34c36;
    margin-left: 5px;
}
.create{
    position: fixed;
    bottom: 10px;
    right: 10px;
    border-radius: 100%  !important;
}
