.loginWrapper {
  position: absolute;
  height: 100vh;
  width: 100vw;
  top: 0;
  bottom: 0;

  form {
    padding: 2rem;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%), 0 1px 5px 0 rgb(0 0 0 / 20%);
  }

  >div {
    width: 100%;
    height: 100%;
    max-width: 600px;
    max-height: 500px;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    overflow: auto;
    padding: 1em 2em;
  }
}