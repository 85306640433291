.icons {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  button {
    background-color: transparent;
    border: 0;

    span {
      display: none;
    }
  }

  @media screen and (max-width: 980px) {
    width: 100%;
    justify-content: space-between;
    padding: 0.5rem;
    padding-top: 0;
    display: flex;

    button {
      width: 50%;
      position: relative;
      border: 1px solid #DADADA;
      display: flex;
      align-items: center;
      justify-content: center;
      outline: none;

      span {
        display: initial;
      }
    }
  }
}

.icon {
  padding-left: 10px;
}

.carImg {
  border-radius: 10%;
}

.car_state_container {
  display: none;
}

.tableContainer {
  margin-left: 20px;
  width: 100%;

  table thead th {
    border: none;
    padding: 10px;
  }

  table td {
    padding: 3px;
    border: none;
    vertical-align: middle;
    font-size: 90%;
  }


  @media screen and (max-width: 980px) {
    width: 100%;
    margin: 0;

    table {
      border: 0;

      thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
      }

      tr {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 0px;
        grid-row-gap: 0px;
        justify-items: center;
        border: 1px solid #000;
        margin-bottom: 1rem;
      }

      td {
        display: flex;
        flex-flow: column;
        font-size: .8rem;
        width: 100%;
        height: 100%;

        span {
          font-family: Arial, Helvetica, sans-serif;
          font-weight: 900;
        }

        &::before {
          font-weight: bold;
          content: attr(data-label);
        }

        // 3 dots front desktop view
        &:first-child {
          div {
            div {
              display: none;
            }
          }
        }

        // Flexbox grid template childs
        // Car image
        &:nth-child(2) {
          grid-area: 1 / 1 / 3 / 2;
          padding-top: 1.6rem;
          padding-left: 1rem;

          // Image availability bottom text
          span {
            position: relative;

            div.car_state_container {
              display: flex;
              position: absolute;
              height: 23px;
              width: 120px;
              padding-bottom: 0;
              left: 0;
              bottom: 0;
              background-color: rgba(0, 0, 0, 0.6);
              color: white;
              border-radius: 10%;

              div {
                margin-left: 5px;
                margin-right: 7px;
              }
            }
          }
        }

        &:nth-child(3) {
          grid-area: 1 / 2 / 2 / 3;
          padding-top: 1.5rem;
        }

        &:nth-child(4) {
          grid-area: 2 / 2 / 3 / 3;
        }

        &:nth-child(5) {
          grid-area: 3 / 1 / 4 / 2;
          margin-left: 2.5rem;
        }

        &:nth-child(6) {
          grid-area: 3 / 2 / 4 / 3;
        }

        &:nth-child(7) {
          grid-area: 4 / 1 / 5 / 2;
          margin-left: 2.5rem;
        }

        &:nth-child(8) {
          grid-area: 4 / 2 / 5 / 3;
        }

        &:nth-child(9) {
          grid-area: 5 / 1 / 6 / 2;
          margin-left: 2.5rem;
        }

        &:nth-child(10) {
          grid-area: 6 / 1 / 7 / 3;
          padding: 10px;
          padding-top: 0;

          span {
            font-weight: initial;
            color: #498CF6;
          }
        }
      }
    }
  }
}