.centerRow {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 10px;
}

.buttons {
  display: flex;
  gap: 20px;
  padding-top: 1rem;
  justify-content: flex-end;
}

.centerButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px;
}

.filterAdjust {
  padding: 2px 0;
}

.companyAdjust {
  width: 100%;
  margin-top: 6px;
}

.bold {
  font-weight: bolder;
  margin: 20px 0;
}

.company_box {
  border-radius: .25rem!important;
  border: 1px solid #ffe6e6;
  padding: 20px 5px;
  margin: 20px 0px;
  background-color: #fdf8f8;
}

.container {
  margin: 30px auto;
  padding: 0.9rem;
  width: 80%;
  background-color: #2c2c2c;
  display: flex;
  align-items: center;
}
.date{
  width: 100%;
}
.uploadInput {
  display: none;
}
.basicBox {
  padding: 0.9rem;
  background-color: #f9f9f9;
  display: flex;
  align-items: center;
}

.row {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
}

.marginBox {
  @extend .basicBox;
  margin: 15px 0px 8px 0px;
}

.margin10 {
  margin: 10px;
}

.noDecoration {
  color: inherit;
  text-decoration: none;
  align-items: center;
  display: flex;
}

.center {
  display: flex;
  justify-content: center;
}
.marginPointer {
  @extend .center;
  cursor: pointer;
  margin: 0 5px;
}
.marginPointerBox {
  @extend .marginPointer;
  border: 1px solid blue;
  border-radius: 5px;
  padding: 1px;
}
.react-dropdown-select-dropdown {
    width: 100%;
}
.icon {
    margin: 0 !important;
    padding: 0 !important;
    background: transparent !important;
    border: none !important;
}
.icon i {
    color: #D34600;
    font-size: 20px !important;
    padding: 0;
    margin: 0;
}

.jss605 {
    color: #6b6b6b;
    margin: 1rem -1rem 0.5rem;
    display: flex;
    padding: 0.5rem;
    align-items: center;
    justify-content: center;
    background-color: #efefef;
}
.disabled{
  opacity: 1 !important;
  background-color: #e9ecef !important;
}
.bgroup {
 margin-right: 10px;
}
.vehicleNav  a {
      color: #2c2c2c;
     
}
 .active {
      border-color: #D34600 #D34600 #fff !important;
      color:#D34600 !important;
 }