.title_container {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.9rem;
    margin-bottom: 1.5rem;
    background-color: #2c2c2c;

    .title {
        margin-left: 1.5rem;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
    }

    .buttons {
        display: flex;
        justify-content: flex-end;
        gap: 1rem;
    }

    .menuButton {
        position: absolute;
        right: 1rem;
        cursor: pointer;

        .popoverMenuButtonWrapper {
            position: absolute;
            right: 0;
            background-color: #EFEFEF;
            padding: 1rem;
            border-radius: 4px;
            z-index: 9999;

            .popoverMenuButton {
                display: flex;
                flex-direction: column;
                gap: 1rem;
            }
        }
    }
}