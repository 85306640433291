.modal_header {
    background-color: #ff9800;
  }
  
.btn_custom {
    background-color: #ff9800 !important;
    border-color: #ff9800 !important;
}
  
.btn_custom:hover {
    background-color: #e88a00 !important;
    border-color: #e88a00 !important;
}

.custom_error {
    font-size: small;
    color: darkred;
}